import { effect } from '@angular/core'
import { getState, signalStoreFeature, withHooks } from '@ngrx/signals'

import logger from '~core/utils/logger'

// eslint-disable-next-line
export const setError = (state, action) => ({
  ...state,
  isLoading: false,
  error: action.payload,
})

//eslint-disable-next-line
export const setIsLoading =
  (prefix: string = null) =>
  (state) => {
    const loadingKey = !!prefix ? `${prefix}IsLoading` : 'isLoading'

    return {
      ...state,
      [loadingKey]: true,
    }
  }

export function withLogger(name: string) {
  return signalStoreFeature(
    withHooks({
      onInit(store) {
        effect(() => {
          const state = getState(store)
          logger.debug(`${name} state changed`, state)
        })
      },
    }),
  )
}
